import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';

import { formatNumberWithCommas } from 'components/Routes/Players/players.service';

import { ICasinoTabGeneralTable } from './CasinoTab.type';

export const casinoMoneyOption = [
  {
    label: 'Real Money',
    value: 'MONEY',
  },
  {
    label: 'Bonus',
    value: 'BONUS',
  },
];
export const moneyOptions = [
  'Betting Option',
  [
    {
      label: 'MONEY',
      value: 'MONEY',
    },
    {
      label: 'BONUS',
      value: 'BONUS',
    },
  ],
];

export const casinoSmallTotalTableColumn = [
  { title: 'Total Bet', dataIndex: 'bet', key: 'bet' },
  { title: 'Total Win', dataIndex: 'win', key: 'win' },
  { title: 'Total GGR', dataIndex: 'ggr', key: 'ggr' },
];

export const casinoSmallTableColumn = [
  { title: 'Bet', dataIndex: 'bet', key: 'bet' },
  { title: 'Win', dataIndex: 'win', key: 'win' },
  { title: 'GGR', dataIndex: 'ggr', key: 'ggr' },
];

export const CasinoTabGeneralTableColumn: ColumnsType<ICasinoTabGeneralTable> =
  [
    {
      title: 'Aggregator/Provider',
      dataIndex: 'aggregator',
      key: 'aggregator',
      width: 200,
    },
    { title: 'Game', dataIndex: 'name', key: 'name' },
    {
      title: 'Bet',
      dataIndex: 'total_bet',
      key: 'total_bet',
      render: (total_bet: number) => formatNumberWithCommas(total_bet),
    },
    {
      title: 'Win',
      dataIndex: 'total_won',
      key: 'total_won',
      render: (total_won: number) => formatNumberWithCommas(total_won),
    },
    {
      title: 'Profit',
      dataIndex: 'ggr',
      key: 'ggr',
      render: (ggr: number) => formatNumberWithCommas(ggr),
    },
    {
      title: 'Extra',
      dataIndex: 'money_option',
      key: 'money_option',
      render: (money_option: string[]) => {
        if (Array.isArray(money_option) && money_option.length) {
          return money_option.join(', ');
        }
        return null;
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (_: any, record: ICasinoTabGeneralTable) => (
        <>
          <div>Start {dayjs(record.start_date).format('DD-MM-YYYY HH:mm')}</div>
          <div>End {dayjs(record.end_date).format('DD-MM-YYYY HH:mm')}</div>
        </>
      ),
    },
  ];
