/* eslint-disable */
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  DownCircleOutlined,
  FilterTwoTone,
  InfoOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Card, Divider, Select, Table, Tooltip } from 'antd';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/es/table';
import axios, { AxiosError } from 'axios';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';

import { IErrorMessage, RootState } from 'redux/store.types';
import {
  selectActiveProjectID,
  selectManualCheck,
} from 'redux/reducers/projects/projects.slice';
import { useAppDispatch } from 'redux/hooks/redux.hooks';
import {
  shortTransactionData,
  updateTransactionStatus,
} from 'redux/reducers/transactions/transaction.thunk';

import NotificationSpinner from '../../Common/NotificationSidebar/NotificationCards/components/NotificationSpinner/NotificationSpinner';

import classes from './Transactions.module.scss';
import InBoModal from './InBoModal/InBoModal';
import OutBoModal from './OutBoModal/OutBoModal';
import { transactionsData, transactionsInsert } from './transactions.service';
import TransactionFilters from './TransactionFilters/TransactionFilters';
import {
  Approved,
  ITransaction,
  TRXfiltersForm,
} from './helpers/Transactions.types';
import {
  colors,
  getFilteredOptions,
  getStatusColor,
  statusColors,
} from './helpers/Constans';
import MetaInfo from './MetaInfo/MetaInfo';
import { getTransactionStatus } from './helpers/Helpers';
import { getProjectInfo } from 'redux/reducers/projects/projects.thunks';

const Transactions: FC = () => {
  const dispatch = useAppDispatch();
  const activeCountryId = useSelector(selectActiveProjectID);
  const { list } = useSelector((state: RootState) => state.transaction);
  const [currentSortOrder, setCurrentSortOrder] = useState<'ASC' | 'DESC'>(
    'ASC',
  );
  const [isInBoModalOpen, setIsInBoModalOpen] = useState(false);
  const [isOutBoModalOpen, setIsOutBoModalOpen] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(true);
  const [TRXId, setTRXId] = useState<number>();
  const [TRXIdCheck, setTRXIdCheck] = useState<number>();
  const [transactionStatus, setTransactionStatus] = useState<{
    [key: number]: string;
  }>({});
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [filters, setFilters] = useState<TRXfiltersForm>({
    dateFrom: dayjs().startOf('day'),
    dateTo: dayjs().endOf('day').set('hour', 23).set('minute', 59),
    amountFrom: 0,
    amountTo: 0,
    phone: '',
    playerId: 0,
    remoteId: '',
    token: '',
    paymentTransactionId: '',
    transactionId: +'',
    opType: [],
    status: ['PENDING'],
    aa_status: [],
    limit: 50,
    page: 1,
    orderBy: 'created_at',
    orderDir: 'DESC',
  });

  const [transactionsList, setTransactionsList] = useState<any[]>([]);
  const manualCheckExist = useSelector(selectManualCheck);

  useEffect(() => {
    dispatch(getProjectInfo());
  }, [activeCountryId]);

  const queryData = useInfiniteQuery(
    ['transactions', filters],
    ({ pageParam = 1 }) => transactionsData.getTransactions(filters, pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.count > filters.limit * allPages.length
          ? allPages.length + 1
          : undefined;
      },
      onSuccess: data => {
        const newList = data.pages.reduce<ITransaction[]>(
          (acc, page) => [...acc, ...page.list],
          [],
        );

        setTransactionsList(newList);
      },
    },
  );

  const mutationManualPush = useMutation({
    mutationFn: ({ transactionId }: { transactionId: number }) => {
      return axios.post('/transaction/manual-push', {
        transactionId,
      });
    },
    onSuccess: () => {
      queryData.refetch();
      queryData.remove();
      toast.success('Manual push has successfully pushed', {
        position: toast.POSITION.TOP_CENTER,
      });
    },
    onError: err => {
      const error = err as unknown as AxiosError<IErrorMessage>;
      toast.error(error.response?.data.message || t('Something went wrong'), {
        position: toast.POSITION.TOP_CENTER,
      });
    },
  });

  const mutationManualCheck = useMutation({
    mutationFn: ({ transactionId }: { transactionId: number }) => {
      return axios.post('/transaction/manual-check', {
        transactionId,
      });
    },
    onSuccess: (data, variables) => {
      const { transactionId } = variables;
      toast.success('Manual push has successfully pushed', {
        position: toast.POSITION.TOP_CENTER,
      });
      const transactionIndex = transactionsList.findIndex(
        transaction => transaction.id === transactionId,
      );
      if (transactionIndex !== -1) {
        const updatedTransactionsList = [...transactionsList];
        updatedTransactionsList[transactionIndex] = {
          ...updatedTransactionsList[transactionIndex],
          status: 'SUCCESS',
        };
        setTransactionsList(updatedTransactionsList);
      }
    },
    onError: err => {
      const error = err as unknown as AxiosError<IErrorMessage>;
      toast.error(error.response?.data.message || t('Something went wrong'), {
        position: toast.POSITION.TOP_CENTER,
      });
    },
  });

  useEffect(() => {
    setTransactionsList(list);
  }, [list]);

  const onPushClick = (data: ITransaction) => {
    const transactionId = data.id;
    setTRXId(transactionId);
    mutationManualPush.mutate({ transactionId });
  };

  const onPushCheckClick = (data: ITransaction) => {
    const transactionId = data.id;
    setTRXIdCheck(transactionId);
    mutationManualCheck.mutate({ transactionId });
  };
  const mutation = useMutation({
    mutationFn: ({
      transactionsIds,
      status,
    }: {
      transactionsIds: number[];
      status: string;
    }) => {
      return axios.post('/transaction/update-status', {
        status,
        transactionsIds,
      });
    },
    onSuccess: () => {
      toast.success(t('Status has successfully changed'), {
        position: toast.POSITION.TOP_CENTER,
      });
    },
    onError: err => {
      queryData.remove();
      queryData.refetch();
      const error = err as unknown as AxiosError<IErrorMessage>;
      toast.error(error.response?.data.message || t('Something went wrong'), {
        position: toast.POSITION.TOP_CENTER,
      });
    },
  });

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const savedScrollPositionRef = useRef<number>(0);

  const rememberScrollPosition = () => {
    if (scrollRef.current) {
      savedScrollPositionRef.current = scrollRef.current.scrollTop;
    }
  };

  const restoreScrollPosition = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = savedScrollPositionRef.current;
    }
  };

  const onStatusChange = (
    transactionsIds: number[],
    status: string,
    isBalk?: boolean,
    data?: any,
  ) => {
    rememberScrollPosition();
    updateTransactionStatus(transactionsIds, status).then(() => {
      if (isBalk && data) {
        const parentTransactionIndex = transactionsList.findIndex(
          item => item.id === data.id,
        );
        if (parentTransactionIndex !== -1) {
          const updatedParent = {
            ...transactionsList[parentTransactionIndex],
            status: status,
          };
          const updatedChildren = updatedParent.children.map((child: any) => ({
            ...child,
            status: status,
          }));
          const updatedList = [...transactionsList];
          updatedList[parentTransactionIndex] = {
            ...updatedParent,
            children: updatedChildren,
          };
          setTransactionsList(updatedList);
        }
      } else {
        setTransactionsList(prevTransactionsList => {
          const updatedList = prevTransactionsList.map(item =>
            transactionsIds.includes(item.id) ? { ...item, status } : item,
          );
          const changesMade =
            JSON.stringify(prevTransactionsList) !==
            JSON.stringify(updatedList);

          if (!changesMade) {
            const newList = prevTransactionsList.map(parentItem => {
              if (
                parentItem.children &&
                parentItem.children.some((childId: any) =>
                  transactionsIds.includes(childId.id),
                )
              ) {
                const updatedParentItem = { ...parentItem };
                updatedParentItem.children = parentItem.children.map(
                  (childItem: any) => {
                    if (transactionsIds.includes(childItem.id)) {
                      return { ...childItem, status };
                    } else {
                      return childItem;
                    }
                  },
                );
                if (getTransactionStatus(updatedParentItem) !== null) {
                  updatedParentItem.status =
                    getTransactionStatus(updatedParentItem);
                }
                return updatedParentItem;
              } else {
                return parentItem;
              }
            });
            return newList;
          }
          return updatedList;
        });
      }
    });
  };

  const onExpanded = (status: boolean, tr: any) => {
    if (status) setExpandedRows([...expandedRows, tr.id]);
    else setExpandedRows(expandedRows.filter(id => id !== tr.id));
  };

  const handleToggleAllRows = () => {
    if (expandedRows.length === 0 && Array.isArray(transactionsList)) {
      setExpandedRows(transactionsList.map(tr => tr.id));
    } else {
      setExpandedRows([]);
    }
  };

  const checkOpType = (word: string): boolean => {
    return word.toUpperCase().startsWith('IN');
  };

  const handleSort = (columnKey: string) => {
    const orderDir = currentSortOrder === 'ASC' ? 'DESC' : 'ASC';
    const newFilters: TRXfiltersForm = {
      ...filters,
      orderBy: columnKey,
      orderDir,
    };

    setFilters(newFilters);

    dispatch(shortTransactionData(newFilters));

    setCurrentSortOrder(orderDir);
  };

  const TransactionsColumns: ColumnsType<ITransaction> = [
    {
      title: 'TRX ID',
      dataIndex: 'id',
      key: 'id',
      render: (_, data) => {
        return data.is_bulk ? `Bulk (${data.count})` : data.id;
      },
    },
    {
      title: 'UID',
      dataIndex: 'user_id',
      key: 'user_id',
      onHeaderCell: (column: any) => ({
        onClick: () => handleSort(column.dataIndex),
      }),
      sorter: true,
      render: (_, data) => (
        <a
          target='_blank'
          href={`http://risks.betunit.com//internet/ccuser/${data.user_id}/`}
          rel='noreferrer'>
          {data.user_id}
        </a>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      onHeaderCell: (column: any) => ({
        onClick: () => handleSort(column.dataIndex),
      }),
      sorter: true,
      render: (_, data) => {
        return (
          <div>
            {(data.is_bulk ? 'Total Amount: ' : '') + data.amount.split('.')[0]}
          </div>
        );
      },
    },
    { title: 'Currency', dataIndex: 'currency', key: 'currency' },
    {
      title: 'Created',
      dataIndex: 'created_at',
      key: 'created_at',
      onHeaderCell: (column: any) => ({
        onClick: () => handleSort(column.dataIndex),
      }),
      sorter: true,
      render: (_, data) => (
        <div>{dayjs.utc(data.created_at).format('DD/MM/YYYY HH:mm')}</div>
      ),
    },
    {
      title: 'Updated',
      dataIndex: 'updated_at',
      key: 'updated_at',
      onHeaderCell: (column: any) => ({
        onClick: () => handleSort(column.dataIndex),
      }),
      sorter: true,
      render: (_, data) =>
        !data.is_bulk && (
          <div>
            <div>{dayjs.utc(data.updated_at).format('DD/MM/YYYY HH:mm')}</div>
          </div>
        ),
    },
    { title: 'Kind', dataIndex: 'op_type', key: 'op_type' },
    {
      title: 'APP',
      dataIndex: 'app_type',
      key: 'app_type',
      render: text => (text === null ? '-' : text),
    },
    {
      title: 'PTRX ID',
      dataIndex: 'gateway_response',
      key: 'gateway_response',
      render: (_, data) => (data.is_bulk ? '' : data.gateway_response),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      onHeaderCell: (column: any) => ({
        onClick: () => handleSort(column.dataIndex),
      }),
      sorter: true,
      render: (_, data) => {
        const availableOptions = getFilteredOptions(data);

        return data.status === 'SUCCESS' || data.status === 'CANCELED' ? (
          <Card
            style={{
              border: 'solid 3px',
              borderRadius: '10px 10px 10px 10px',
              borderColor:
                data.to_check === 1 ? 'orange' : statusColors[data.status],
            }}
            className={classes.tableStatus}>
            {data.status}
          </Card>
        ) : (
          <Tooltip
            placement='right'
            title={data.to_check === 1 ? 'Payment failed, please check' : ''}>
            <Select
              onChange={value => {
                setTransactionStatus({
                  ...transactionStatus,
                  [data.id]: value,
                });
                onStatusChange(
                  data.is_bulk ? data.children.map(tr => tr.id) : [data.id],
                  value,
                  data.is_bulk,
                  data,
                );
              }}
              style={{
                width: '8rem',
                border: `solid 3px ${
                  getTransactionStatus(data) === null
                    ? 'transparent'
                    : getStatusColor(data.status, data.to_check ?? 0)
                    ? getStatusColor(data.status, data.to_check ?? 0)
                    : statusColors[transactionStatus[data.id] || data.status]
                }`,
                borderRadius: '10px',
                // borderColor: getStatusColor(data.status, data.to_check ?? 0)
                //   ? getStatusColor(data.status, data.to_check ?? 0)
                //   : statusColors[transactionStatus[data.id] || data.status],
              }}
              defaultValue={
                transactionStatus[data.id] || getTransactionStatus(data)
              }
              value={transactionStatus[data.id] || getTransactionStatus(data)}
              disabled={!getTransactionStatus(data)}
              options={availableOptions} // Use filtered options here

              // options={
              //   validOptionsList[data.to_check ? 'CHECKED' : 'UNCHECKED'][
              //     data.status
              //   ]
              // }
            />
          </Tooltip>
        );
      },
    },
    { title: 'Code', dataIndex: 'code', key: 'code' },
    { title: 'Token', dataIndex: 'token', key: 'token' },
    { title: "User's Phone", dataIndex: 'user_phone', key: 'user_phone' },
    { title: 'MSISDN', dataIndex: 'msisdn', key: 'msisdn' },
    { title: 'Operator', dataIndex: 'op_name', key: 'op_name' },
    {
      title: 'RTX ID',
      dataIndex: 'gateway_trx_id',
      key: 'gateway_trx_id',
      render: (_, data) => (data.is_bulk ? '' : data.gateway_trx_id),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (_, data) => {
        if (data.meta_info) {
          try {
            return <div>{JSON.parse(data.meta_info).sa_username}</div>;
          } catch {
            /* empty */
          }
        }
        return '';
      },
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      render: (_, data) => {
        if (data.meta_info && !data.is_bulk) {
          try {
            return <div>{JSON.parse(data.meta_info).reason}</div>;
          } catch {
            /* empty */
          }
        }
        return '';
      },
    },
    {
      title: 'CHECK',
      dataIndex: 'autochecked',
      key: 'autochecked',
      render: (_, data) => {
        const metaInfo = JSON.parse(data.meta_info);
        const aaEnabled =
          metaInfo?.aa_messages && metaInfo?.aa_messages?.aa_enabled === true;
        const redBackground =
          data.aa_status === Approved.REJECTED && aaEnabled === true;
        const hasTrueValue = Object.values(metaInfo?.aa_messages || {}).some(
          value => {
            return value !== null && value !== false;
          },
        );
        const allNullOrFalse = Object.values(metaInfo?.aa_messages || {}).every(
          value => {
            return value === null || value === false;
          },
        );
        const className = [
          !aaEnabled && data.aa_status === Approved.REJECTED && hasTrueValue
            ? classes.checkAaEnabled
            : !aaEnabled &&
              data.aa_status === Approved.REJECTED &&
              allNullOrFalse
            ? classes.greenWithGrey
            : classes.check,
        ].join(' ');

        return (
          <Card
            className={className}
            style={{
              backgroundColor: redBackground ? 'red' : colors[data.aa_status],
            }}>
            <div>
              {data.aa_status === Approved.REJECTED &&
              !aaEnabled &&
              allNullOrFalse ? (
                <DownCircleOutlined />
              ) : data.aa_status === Approved.REJECTED ? (
                <InfoOutlined />
              ) : (
                <DownCircleOutlined />
              )}
            </div>
          </Card>
        );
      },
    },
    {
      title: (
        <Button size='small' onClick={handleToggleAllRows}>
          {expandedRows.length === 0 ? <PlusOutlined /> : <MinusOutlined />}
        </Button>
      ),
    },
    Table.EXPAND_COLUMN,
    {
      title: 'PUSH/CHECK',
      key: 'autoPush',
      render: (_, data: ITransaction) => (
        <>
          {(manualCheckExist == 1 && data.status === 'WAITGW') ||
          data.status === 'INDETERMINATE' ? (
            <Button
              loading={
                data.status === 'WAITGW' &&
                data.id === TRXId &&
                mutationManualCheck.isLoading
              }
              onClick={() => onPushCheckClick(data)}
              type='primary'
              disabled={
                data.status !== 'WAITGW' && data.status !== 'INDETERMINATE'
              }
              danger>
              CHECK
            </Button>
          ) : (
            <Button
              loading={
                data.status === 'APPROVED' &&
                data.id === TRXIdCheck &&
                mutationManualPush.isLoading
              }
              onClick={() => onPushClick(data)}
              disabled={data.status !== 'APPROVED'}
              type='primary'
              danger>
              PUSH
            </Button>
          )}
        </>
      ),
    },
  ];

  const TRXInsert = useQuery(['filters-insert'], () =>
    transactionsInsert.getTRXInsert(),
  );
  const totalCount = queryData.data?.pages?.length
    ? queryData.data.pages[queryData.data.pages.length - 1].count
    : 0;
  const totalAmount = queryData.data?.pages?.length
    ? queryData.data.pages[queryData.data.pages.length - 1].amount
    : 0;
  const usersCount = queryData.data?.pages?.length
    ? queryData.data.pages[queryData.data.pages.length - 1].users_count
    : 0;

  const onInBoModalOpenClick = () => {
    setIsInBoModalOpen(!isInBoModalOpen);
  };
  const onOutBoModalOpenClick = () => {
    setIsOutBoModalOpen(!isOutBoModalOpen);
  };
  const onFiltersClick = () => {
    setIsFiltersOpen(!isFiltersOpen);
  };

  useEffect(() => {
    queryData.refetch();
    TRXInsert.refetch();
  }, [activeCountryId]);

  useEffect(() => {
    if (mutation.isSuccess) {
      queryData.refetch().then(() => {
        restoreScrollPosition();
      });
    }
  }, [mutation.isSuccess, queryData.data, InfiniteScroll]);

  const MetaInfoExtandable = useCallback(
    (transaction: ITransaction) => (
      <>
        {transaction.aa_status === 'REJECTED' && (
          <MetaInfo key={transaction.id} data={transaction?.meta_info} />
        )}

        {transaction.is_bulk && (
          <div className={classes.trxTabBody}>
            <Table
              rowClassName={classes.childRowColor}
              rowKey={data => data.id}
              size='small'
              columns={TransactionsColumns as any}
              dataSource={transaction.children}
              footer={() => null}
              pagination={false}
            />
          </div>
        )}
      </>
    ),
    [],
  );

  return (
    <>
      <Divider orientation='left'>Transactions</Divider>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 5,
        }}>
        <Tooltip title='Filter'>
          <FilterTwoTone
            onClick={onFiltersClick}
            style={{ fontSize: 24, cursor: 'pointer' }}
          />
        </Tooltip>
        <div style={{ display: 'flex', paddingBottom: 10 }}>
          <div style={{ marginRight: '0.5rem' }}>
            <Button onClick={onOutBoModalOpenClick} type='primary' danger>
              <MinusOutlined />
            </Button>
          </div>
          <div>
            <Button onClick={onInBoModalOpenClick} type='primary'>
              <PlusOutlined />
            </Button>
          </div>
        </div>
      </div>
      <InBoModal
        TRXfilters={TRXInsert.data?.op_types.IN}
        isInBoModalOpen={isInBoModalOpen}
        setIsInBoModalOpen={setIsInBoModalOpen}
      />
      <OutBoModal
        TRXfilters={TRXInsert.data?.op_types.OUT}
        isOutBoModalOpen={isOutBoModalOpen}
        setIsOutBoModalOPen={setIsOutBoModalOpen}
      />
      <div
        style={{
          display: !isFiltersOpen ? 'none' : '',
        }}>
        <TransactionFilters
          refetch={queryData.refetch}
          remove={queryData.remove}
          filter={filters}
          setFilters={setFilters}
          initialFilters={filters}
          transactionList={transactionsList}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '1rem',
        }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Card
            bodyStyle={{
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              minWidth: 150,
              height: 30,
              backgroundColor: 'rgb(120, 177, 235)',
              borderRadius: 5,
            }}
            headStyle={{ padding: 10 }}
            size='small'>
            Total Count: {totalCount}
          </Card>
          <Card
            bodyStyle={{
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              minWidth: 150,
              height: 30,
              backgroundColor: 'rgb(120, 177, 235)',
              borderRadius: 5,
            }}
            size='small'
            headStyle={{ padding: 10 }}>
            Total Amount: {totalAmount}
          </Card>
          <Card
            bodyStyle={{
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              minWidth: 150,
              height: 30,
              backgroundColor: 'rgb(120, 177, 235)',
              borderRadius: 5,
            }}
            headStyle={{ padding: 10 }}
            size='small'>
            Users Count: {usersCount}
          </Card>
        </div>
      </div>

      <div>
        <InfiniteScroll
          next={queryData.fetchNextPage}
          loader={<NotificationSpinner />}
          hasMore={!!queryData.hasNextPage}
          dataLength={transactionsList?.length || 0}>
          <div className={classes.trxTabBody}>
            <Table
              rowKey={data => data.id}
              rowClassName={data =>
                data.is_manual === 1 ? classes.manual : ''
              }
              size='small'
              columns={TransactionsColumns}
              expandable={{
                expandedRowRender: MetaInfoExtandable,
                rowExpandable: data => {
                  const metaInfo = JSON.parse(data.meta_info);
                  const aaEnabled =
                    metaInfo?.aa_messages &&
                    metaInfo?.aa_messages?.aa_enabled === true;
                  const hasTrueValue = Object.values(
                    metaInfo?.aa_messages || {},
                  ).some(value => {
                    return value !== null && value !== false;
                  });
                  return (
                    (data.aa_status === 'REJECTED' &&
                      // !aaEnabled &&
                      hasTrueValue) ||
                    data.is_bulk
                  );
                },
                expandedRowKeys: expandedRows,
                onExpand: (status, tr) => onExpanded(status, tr),
                childrenColumnName: 'children_old',
              }}
              dataSource={transactionsList}
              loading={queryData.isLoading}
              footer={() => null}
              pagination={false}
            />
          </div>
        </InfiniteScroll>
      </div>
    </>
  );
};
export default Transactions;

/* eslint-enable */
