/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  sportBetTypeOption,
  sportStatusOption,
} from 'components/Routes/Players/Player/components/SportTab/helpers/costants';

const transformToKeys = (
  options: { value: string; key: number }[],
  values?: string[],
): number[] => {
  if (!values) return [];

  return values
    .map(value => {
      const option = options.find(option => option.value === value);
      return option ? option.key : null;
    })
    .filter((key): key is number => key !== null);
};

export const getPlayerSportThunk = createAsyncThunk(
  'sport/getPlayerSportThunk',
  async (data: any) => {
    try {
      const transformedData = {
        ...data,
        filters: {
          ...data.filters,
          status: transformToKeys(sportStatusOption, data.filters.status),
          ztype: transformToKeys(sportBetTypeOption, data.filters.ztype),
        },
      };
      const response = await axios.post(
        '/player/list-player-sport-bets',
        transformedData,
      );
      return response.data;
    } catch (error) {
      console.error('Error in getPlayerSportThunk:', error);
      throw error;
    }
  },
);
