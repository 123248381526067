/* eslint-disable */

import { FC, useEffect, useState } from 'react';
import { Col, Row, Table, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useAppDispatch } from 'redux/hooks/redux.hooks';
import {
  getAggregatorsThunks,
  getCasinoBetsBySessionThunks,
} from 'redux/reducers/players/casino/casinoThunk';

import CasinoTabFilter from './components/CasinoTabFilter/CasinoTabFilter';
import CasinoTabSmallTable from './components/CasinoTabSmallTable/CasinoTabSmallTable';
import { CasinoTabGeneralTableColumn } from './helpers/costants';
import { ICasinoTabGeneralTable } from './helpers/CasinoTab.type';
import classes from './CasinoTab.module.scss';
import SessionModal from './components/CasinoSessionModal/SessionModal';
import dayjs, { Dayjs } from 'dayjs';
import { cleanSessons } from 'redux/reducers/players/casino/casinoSlice';
import { setChoosenCasinoBetId } from 'redux/reducers/players/playersSlice';

const CasinoTab: FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { playerCasinoData, count } = useSelector(
    (state: any) => state.casinoSlice.data,
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([
    dayjs().subtract(1, 'week').startOf('day'),
    dayjs().endOf('day').set('hour', 23).set('minute', 59),
  ]);
  const [filtersForModal, setFiltersForModal] = useState({});
  const { choosenBetCasinoId } = useSelector((state: any) => state.players);

  useEffect(() => {
    dispatch(getAggregatorsThunks());
  }, [id]);

  useEffect(() => {
    const [date_from, date_to] = dateRange;
    if (choosenBetCasinoId !== null) {
      dispatch(
        getCasinoBetsBySessionThunks({
          limit: 50,
          page: 1,
          filters: {
            date_from: date_from,
            date_to: date_to,
            player_id: id,
            session: null,
            find_by_key: 'bet_id',
            bet_id: choosenBetCasinoId,
          },
        }),
      );
      setFiltersForModal({
        date_from: date_from,
        date_to: date_to,
        player_id: id,
        session: choosenBetCasinoId,
      });
      setIsModalVisible(true);
    }
  }, [choosenBetCasinoId]);

  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleSessionClick = (record: any) => {
    const [date_from, date_to] = dateRange;
    dispatch(
      getCasinoBetsBySessionThunks({
        limit: 50,
        page: 1,
        filters: {
          date_from: date_from,
          date_to: date_to,
          player_id: id,
          session: record.session,
          find_by_key: 'session',
          bet_id: null,
        },
      }),
    );
    setFiltersForModal({
      date_from: date_from,
      date_to: date_to,
      player_id: id,
      session: record,
    });
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setFiltersForModal({});
    dispatch(cleanSessons());
    dispatch(setChoosenCasinoBetId(null));
  };

  const columnsWithInfoIcon = [
    {
      title: 'Session Id',
      dataIndex: 'session',
      key: 'session',
      render: (_: any, record: ICasinoTabGeneralTable) => (
        <Tooltip title={record.session}>
          <p
            className={classes.sessionId}
            onClick={() => handleSessionClick(record)}>
            {record.session}
          </p>
        </Tooltip>
      ),
      width: 100,
    },
    ...CasinoTabGeneralTableColumn,
  ];

  const handleDateChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    setDateRange(
      dates || [
        dayjs().subtract(1, 'week').startOf('day'),
        dayjs().endOf('day').set('hour', 23).set('minute', 59),
      ],
    );
  };

  return (
    <>
      <Row gutter={[2, 24]}>
        <Col span={12}>
          <CasinoTabFilter
            currentPage={currentPage}
            pageSize={pageSize}
            onDateChange={handleDateChange}
          />
        </Col>
        <Col style={{ marginTop: 20 }} span={12}>
          <CasinoTabSmallTable />
        </Col>
        <Col span={24}>
          <Table
            scroll={{ x: 1000, y: 600 }}
            size='middle'
            dataSource={playerCasinoData}
            bordered
            columns={columnsWithInfoIcon}
            onChange={handleTableChange}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: count,
              pageSizeOptions: ['10', '20', '50', '100'],
              showQuickJumper: true,
              showSizeChanger: true,
            }}
          />
          <SessionModal
            selectedBet={choosenBetCasinoId}
            isModalVisible={isModalVisible}
            handleModalClose={handleModalClose}
            filters={filtersForModal}
          />
        </Col>
      </Row>
    </>
  );
};

export default CasinoTab;
