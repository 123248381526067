/* eslint-disable */
import { FC, useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Row, Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import FormItem from 'antd/es/form/FormItem';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'redux/hooks/redux.hooks';
import { getPlayerCasinoData } from 'redux/reducers/players/casino/casinoThunk';

import GlobalLoader from 'components/Common/GlobalLoader/GlobalLoader';
import { casinoMoneyOption, moneyOptions } from '../../helpers/costants';
import { getSportCasinoGamesData } from 'redux/reducers/players/balanceLog/balanceLogThunk';
import CheckboxGroup from 'components/Routes/Transactions/TransactionFilters/CheckboxGroup';

const { RangePicker } = DatePicker;

interface ILocalData {
  currentPage: any;
  pageSize: any;
  onDateChange: (dates: [Dayjs | null, Dayjs | null] | null) => void;
}

const CasinoTabFilter: FC<ILocalData> = ({
  currentPage,
  pageSize,
  onDateChange,
}) => {
  const { id } = useParams<{ id: string }>();

  const [localData, setLocalData] = useState<any>({
    date_from: dayjs().subtract(1, 'week').startOf('day'),
    date_to: dayjs().endOf('day').set('hour', 23).set('minute', 59),
  });
  const dispatch = useAppDispatch();
  const { loading } = useSelector((state: any) => state.casinoSlice);
  const { aggregators } = useSelector((state: any) => state.casinoSlice.data);
  const [filters, setFilters] = useState<any>([]);
  const [selectedFilters, setSelectedFilters] = useState<any>([]);

  useEffect(() => {
    // Check if aggregators is not an empty array
    const newFilters =
      aggregators.length > 0
        ? [['Supplier', [...aggregators]], moneyOptions]
        : [moneyOptions];

    setFilters(newFilters);
  }, [aggregators]);

  useEffect(() => {
    const hasFilters = aggregators.length > 0;
    hasFilters &&
      dispatch(
        getPlayerCasinoData({
          limit: pageSize,
          page: currentPage,
          filters: {
            ...localData,
            aggregators: selectedFilters['Supplier'],
            money_options: selectedFilters['Betting Option'],
            player_id: id,
          },
        }),
      );
  }, [aggregators, currentPage, pageSize]);

  const handleRangeChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (dates) {
      const [start, end] = dates;
      setLocalData((prevState: any) => ({
        ...prevState,
        date_from: start
          ? dayjs(start.format())
          : dayjs().subtract(1, 'week').startOf('day'),
        date_to: end
          ? dayjs(end.format())
          : dayjs().endOf('day').set('hour', 23).set('minute', 59),
      }));
      onDateChange(dates);
    }
  };

  const onSearch = () => {
    dispatch(
      getPlayerCasinoData({
        limit: pageSize,
        page: currentPage,
        filters: {
          ...localData,
          aggregators: selectedFilters['Supplier'],
          money_options: selectedFilters['Betting Option'],
          player_id: id,
        },
      }),
    );
    const { date_to, date_from } = localData;
    if (id) {
      dispatch(
        getSportCasinoGamesData({
          player_id: parseInt(id),
          date_to,
          date_from,
          sections: ['casino'],
        }),
      );
    }
  };

  useEffect(() => {
    const { date_to, date_from } = localData;
    if (id) {
      dispatch(
        getSportCasinoGamesData({
          player_id: parseInt(id),
          date_to,
          date_from,
          sections: ['casino'],
        }),
      );
    }
  }, []);

  const onFilterChange = (name: string, values: any[]) => {
    setSelectedFilters((prevState: any) => ({ ...prevState, [name]: values }));
  };

  const onAllCheck = (name: string, values: any[]) => {
    setSelectedFilters((prevState: any) => ({ ...prevState, [name]: values }));
  };

  return (
    <>
      <Form layout='vertical'>
        <Row>
          <Col span={14}>
            <FormItem label='Select Range'>
              <RangePicker
                showTime
                defaultValue={[localData.date_from, localData.date_to]}
                onChange={handleRangeChange}
              />
            </FormItem>
          </Col>
          <Row>
            <Col span={20}>
              {filters.length > 0 &&
                (filters as any[]).map(([key, options]) => (
                  <Row
                    key={key}
                    style={{
                      paddingBottom: 5,
                      paddingLeft: 5,
                      flexWrap: 'nowrap',
                    }}>
                    <CheckboxGroup
                      span={filters.length == 2 ? 4 : 7}
                      name={key}
                      options={options?.map(
                        (item: { label: string }) => item.label,
                      )}
                      value={selectedFilters[key] || []}
                      onFilterChange={onFilterChange}
                      onAllCheck={onAllCheck}
                    />
                  </Row>
                ))}
            </Col>
            <Col span={3}>
              <Row>&nbsp;</Row>
              <Row>&nbsp;</Row>
              <Row>&nbsp;</Row>
              <Row>&nbsp;</Row>
              <Form.Item label='&nbsp;'>
                <Button disabled={loading} onClick={onSearch} type='primary'>
                  Search
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Row>
      </Form>
    </>
  );
};

export default CasinoTabFilter;
