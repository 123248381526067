import { Col, Row, Table } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { formatNumberWithCommas } from 'components/Routes/Players/players.service';

import {
  casinoSmallTableColumn,
  casinoSmallTotalTableColumn,
} from '../../helpers/costants';

interface CasinoTotalData {
  bet: any;
  ggr: any;
  win: any;
}

const CasinoTabSmallTable: FC = () => {
  const { casino } = useSelector(
    (state: any) => state.balanceLogSlice.sportCasinoGames,
  );
  const { loading } = useSelector((state: any) => state.balanceLogSlice);
  const [casinoTotalData, setCasinoTotalData] = useState<CasinoTotalData[]>([]);
  const [casinoData, setCasinoData] = useState<CasinoTotalData[]>([]);

  useEffect(() => {
    if (casino && casino.total) {
      setCasinoTotalData([
        {
          bet: formatNumberWithCommas(casino.total.total_bet),
          ggr: formatNumberWithCommas(casino.total.ggr),
          win: formatNumberWithCommas(casino.total.total_won),
        },
      ]);
      setCasinoData([
        {
          bet: casino.total_by_date.total_bet,
          ggr: casino.total_by_date.ggr,
          win: casino.total_by_date.total_won,
        },
      ]);
    }
  }, [casino]);

  return (
    <Row>
      <Col span={12}>
        <Table
          dataSource={casinoTotalData}
          columns={casinoSmallTotalTableColumn}
          pagination={false}
          bordered
          size='middle'
          loading={loading}
        />
      </Col>
      <Col style={{ marginLeft: 5 }} span={10}>
        <Table
          dataSource={casinoData}
          columns={casinoSmallTableColumn}
          pagination={false}
          bordered
          size='middle'
          loading={loading}
        />
      </Col>
    </Row>
  );
};

export default CasinoTabSmallTable;
