import { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from 'react-query';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';

import { IErrorMessage } from 'redux/store.types';
import { ProjectService } from 'services/projects';
import {
  selectActiveProjectID,
  selectPhoneNumberCode,
  setPhoneNumberCode,
  updateProjectInfo,
} from 'redux/reducers/projects/projects.slice';

import { ITRXFilter } from '../helpers/Transactions.types';
import { filtersForUserIdOrNumber } from '../helpers/Constans';

import Classes from './InBoModal.module.scss';
import { IInBoForm, IInBoRequest, IInBoResponse } from './inBo.types';

type PropTypes = {
  TRXfilters: ITRXFilter[] | undefined;
  isInBoModalOpen: boolean;
  setIsInBoModalOpen: (x: boolean) => void;
};

const InBoModal: FC<PropTypes> = ({
  setIsInBoModalOpen,
  isInBoModalOpen,
  TRXfilters,
}) => {
  const [form] = Form.useForm();

  const activeCountryId = useSelector(selectActiveProjectID);
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState<string>('usersInput');
  const phoneNumberCode = useSelector(selectPhoneNumberCode);
  const [selectedOpType, setSelectedOpType] = useState<string>('');
  const [isAwaAffected, setIsAwaAffected] = useState<boolean>(false);

  console.log(isAwaAffected);

  const handleOpTypeChange = (value: string) => {
    setSelectedOpType(value);
  };

  const amountData = useQuery(['project/info'], ProjectService.getProjectInfo, {
    onSuccess: data => {
      dispatch(updateProjectInfo(data));
      dispatch(setPhoneNumberCode(data.project_phone_country_code));
    },
  });
  const maxAmountData = amountData.data?.mi_limit;
  const numericLimit = maxAmountData ? parseFloat(maxAmountData) : undefined;
  const [amountValue, setAmountValue] = useState<string>('');

  useEffect(() => {
    amountData.refetch();
  }, [activeCountryId]);

  useEffect(() => {
    if (!isInBoModalOpen) {
      form.resetFields();
    }
    if (isInBoModalOpen) {
      setIsAwaAffected(false);
    }
  }, [isInBoModalOpen]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAmountValue(e.target.value);
  };

  const mutation = useMutation({
    mutationFn: (data: IInBoRequest) => {
      const { token, ...rest } = data;
      return axios.post<IInBoResponse>('/transaction/insert', rest, {
        headers: {
          'x-tf-token': token,
        },
      });
    },
    onSuccess: res => {
      const requestBody: IInBoRequest = JSON.parse(res.config.data);
      const requestedIds = requestBody.usersIds;
      const invalidIds = res.data.missingPlayersIds;
      setIsInBoModalOpen(false);
      form.resetFields();
      if (requestedIds?.length !== invalidIds?.length) {
        const insertedIds = !invalidIds?.length
          ? requestedIds
          : requestedIds.filter(id => !invalidIds.includes(id));
        toast.success(
          `${t(
            'Тhese IDs successfully inserted',
          )} - ${insertedIds?.toString()}`,
          { position: toast.POSITION.TOP_CENTER },
        );
      }
      if (invalidIds?.length) {
        toast.error(
          `${t("Тhese IDs don't exist")} - ${invalidIds.toString()}`,
          { autoClose: false, position: toast.POSITION.TOP_CENTER },
        );
      }
    },
    onError: err => {
      console.log(err, 'mayis');

      const error = err as unknown as AxiosError<IErrorMessage>;
      toast.error(error.response?.data.message || t('Something went wrong'), {
        position: toast.POSITION.TOP_CENTER,
      });
    },
  });

  const filters = TRXfilters?.map(item => ({ value: item.name }));
  const paymentTrxIdRequiredLevel = TRXfilters?.filter(
    item => item.name === selectedOpType,
  ).map(item => item?.payment_trx_id_required_level)[0];
  const reasonRequiredLevel = TRXfilters?.filter(
    item => item.name === selectedOpType,
  ).map(item => item?.reason_required_level)[0];
  const remoteTrxIdRequiredLevel = TRXfilters?.filter(
    item => item.name === selectedOpType,
  ).map(item => item?.remote_trx_id_required_level)[0];

  const onFinish = (data: IInBoForm) => {
    const requestBody: IInBoRequest = {
      amount: data.amount,
      opType: data?.opType,
      usersIds: data.usersInput?.split(',').map(Number),
      phone: data.phone,
      paymentTransactionId: data.paymentTransactionId,
      remoteTransactionId: data.remoteTransactionId,
      reason: data.reason,
      isAwaAffected,
      type: 'IN',
      token: data.token,
    };

    if (requestBody.paymentTransactionId === '') {
      delete requestBody.paymentTransactionId;
    }
    if (requestBody.remoteTransactionId === '') {
      delete requestBody.remoteTransactionId;
    }
    mutation.mutate(requestBody);
  };

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
  };

  const changeAwaEffect = () => {
    setIsAwaAffected(!isAwaAffected);
  };

  return (
    <Modal
      open={isInBoModalOpen}
      width={400}
      footer={null}
      onCancel={() => setIsInBoModalOpen(false)}
      title='In'>
      <Form
        form={form}
        validateTrigger='onSubmit'
        disabled={mutation.isLoading}
        onFinish={onFinish}>
        <Row>
          {selectedOption === 'usersInput' ? (
            <Form.Item
              style={{ width: '58.5%' }}
              rules={[
                {
                  required: true,
                  message: 'The field is required!',
                },
                {
                  pattern: /^[\d,]+$/,
                  message: 'Input must include only chars and comma!',
                },
              ]}
              name='usersInput'>
              <Input placeholder='Player ID' />
            </Form.Item>
          ) : (
            <>
              <span style={{ marginTop: 5, marginRight: 2 }}>
                {phoneNumberCode}
              </span>
              <Form.Item
                style={{ width: '49%' }}
                rules={[
                  {
                    required: true,
                    message: 'The field is required!',
                  },
                  {
                    pattern: /^\d{6,}$/,
                    message:
                      'Input must include only numbers and be at least 6 characters long!',
                  },
                ]}
                name='phone'>
                <Input placeholder='Phone Number' />
              </Form.Item>
            </>
          )}
          <Form.Item>
            <Select
              defaultValue='Player ID'
              onChange={handleOptionChange}
              style={{ width: '140px' }}>
              {filtersForUserIdOrNumber.map(filter => (
                <Select.Option key={filter.value} value={filter.value}>
                  {filter.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Row>
        <Row>
          <Col span={14}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'The field is required!',
                },
                {
                  pattern: /^\d+(\.\d{1,2})?$/,
                  message: 'Invalid input. Please enter a valid number.',
                },
                {
                  validator: (_, value) => {
                    if (value === '') {
                      return Promise.resolve();
                    }
                    if (
                      numericLimit !== undefined &&
                      parseFloat(value) > numericLimit
                    ) {
                      return Promise.reject(
                        new Error(`Max amount ${numericLimit}`),
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              name='amount'>
              <Input
                onChange={handleInputChange}
                placeholder='Amount'
                type='number'
                value={amountValue}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name='opType'>
              <Select
                onChange={handleOpTypeChange}
                style={{ width: '9rem' }}
                options={filters}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              style={{
                display:
                  paymentTrxIdRequiredLevel === 'NONE' ? 'none' : 'block',
              }}
              name='paymentTransactionId'
              rules={[
                {
                  required: paymentTrxIdRequiredLevel === 'REQUIRED',
                  message: 'Payment TRX ID is required.',
                },
              ]}>
              <Input
                placeholder={
                  paymentTrxIdRequiredLevel === 'REQUIRED'
                    ? 'Payment TRX ID *'
                    : 'Payment TRX ID'
                }
              />
            </Form.Item>
            <Form.Item
              style={{
                display: remoteTrxIdRequiredLevel === 'NONE' ? 'none' : 'block',
              }}
              name='remoteTransactionId'
              rules={[
                {
                  required: remoteTrxIdRequiredLevel === 'REQUIRED',
                  message: 'Remote TRX ID is required.',
                },
              ]}>
              <Input
                placeholder={
                  remoteTrxIdRequiredLevel === 'REQUIRED'
                    ? 'Remote TRX ID *'
                    : 'Remote TRX ID'
                }
              />
            </Form.Item>
            <Form.Item
              style={{
                display: reasonRequiredLevel === 'NONE' ? 'none' : 'block',
              }}
              name='reason'
              rules={[
                {
                  required: reasonRequiredLevel === 'REQUIRED',
                  message: 'Reason is required.',
                },
              ]}>
              <Input
                placeholder={
                  reasonRequiredLevel === 'REQUIRED' ? 'Reason *' : 'Reason'
                }
              />
            </Form.Item>
            <Form.Item name='isAwaAffected'>
              <Checkbox value={isAwaAffected} onChange={changeAwaEffect}>
                AWA
              </Checkbox>
            </Form.Item>

            {/* <Form.Item name='token'>
              <Input.Password
                prefix={<LockOutlined className='site-form-item-icon' />}
                placeholder='Secret Token '
              />
            </Form.Item> */}
          </Col>
        </Row>
        <div className={Classes.SaveAndCancelButtons}>
          <Button
            onClick={() => setIsInBoModalOpen(false)}
            type='primary'
            danger>
            Cancel
          </Button>
          <Popconfirm
            overlayStyle={{ width: '22rem' }}
            title={`Are you sure the correct amount is: ${
              form.getFieldValue('amount') || ''
            } `}
            onConfirm={() => form.submit()}
            placement='topRight'>
            <Button
              loading={mutation.isLoading}
              type='primary'
              htmlType='submit'>
              Save
              <SaveOutlined />
            </Button>
          </Popconfirm>
        </div>
      </Form>
    </Modal>
  );
};

export default InBoModal;
