/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Row, Table } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useAppDispatch } from 'redux/hooks/redux.hooks';
import {
  getPlayerTrxThunk,
  getTrxFiltersThunk,
} from 'redux/reducers/players/playerTrx/playerTrxThunk';
import { useSelector } from 'react-redux';
import {
  columnsInOut,
  trxApprovedTypeTreeOptions,
  trxStatusTypeOption,
} from '../../helpers/costants';
import GlobalLoader from 'components/Common/GlobalLoader/GlobalLoader';

import { formatNumberWithCommas } from 'components/Routes/Players/players.service';
import CheckboxGroup from 'components/Routes/Transactions/TransactionFilters/CheckboxGroup';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { selectAppTypes } from 'redux/reducers/projects/projects.slice';

interface TRXTabFilterProps {
  id: string | undefined;
  currentPage: any;
  pageSize: any;
  setCurrentPage: (page: any) => void;
}

const TRXTabFilter: React.FC<TRXTabFilterProps> = ({
  id,
  currentPage,
  setCurrentPage,
  pageSize,
}) => {
  const [localData, setLocalData] = useState({
    dateFrom: dayjs().subtract(1, 'week').startOf('day'),
    dateTo: dayjs().endOf('day').set('hour', 23).set('minute', 59),
  });
  const dispatch = useAppDispatch();
  const { trxFilters } = useSelector((state: any) => state.playerTrxSlice);
  const { additionalOpTypes } = useSelector(
    (state: any) => state.playerTrxSlice.trxFilters,
  );
  const {
    deposit_amount,
    total_deposit_amount,
    total_withdraw_amount,
    withdraw_amount,
  } = useSelector((state: any) => state.playerTrxSlice.playerTrx.data);
  const [filtersData, setFiltersData] = useState<{
    [key: string]: CheckboxValueType[];
  }>({});
  const [localFilters, setLocalFilters] = useState<any>([]);
  const appTypes = useSelector(selectAppTypes);

  useEffect(() => {
    dispatch(getTrxFiltersThunk());
  }, []);

  useEffect(() => {
    const data = trxFilters.op_types;
    Object.keys(data).forEach(item => {
      setFiltersData(prev => ({
        ...prev,
        [item]: data[item].map((el: any) => el.name),
      }));
    });
  }, [trxFilters, additionalOpTypes]);

  useEffect(() => {
    const app = appTypes.map(name => ({ name, type: 'APPTYPES' }));
    const filters = [...Object.entries(trxFilters.op_types)];
    if (additionalOpTypes && additionalOpTypes.length > 0) {
      filters.push([
        'BONUS',
        additionalOpTypes.map((el: any) => ({ name: el })),
      ]);
    }
    filters.push(['AppType', trxApprovedTypeTreeOptions]);
    filters.push(['Status', trxStatusTypeOption]);
    filters.push(['APP', app]);
    setLocalFilters(filters);
  }, [additionalOpTypes, trxFilters.op_types, appTypes]);

  useEffect(() => {
    const hasFilters = Object.keys(filtersData).length > 0;
    if (id && hasFilters) {
      const obj = {
        opType: [
          ...(filtersData.IN || []),
          ...(filtersData.OUT || []),
          ...(filtersData.BONUS || []),
        ],
        aa_status: [...(filtersData.AppType || [])],
        status: [...(filtersData.Status || [])],
        appType: filtersData.APP,
        dateFrom: localData.dateFrom,
        dateTo: localData.dateTo,
        playerId: parseInt(id),
        limit: pageSize,
        page: currentPage,
        orderBy: 'updated_at',
        orderDir: 'DESC',
      };
      dispatch(getPlayerTrxThunk(obj));
    }
  }, [id, currentPage, pageSize]);

  // const dataInOut = [
  //   {
  //     key: '1',
  //     label1: 'In',
  //     data1: formatNumberWithCommas(deposit_amount),
  //     label2: 'Total In',
  //     data2: formatNumberWithCommas(total_deposit_amount),
  //   },
  //   {
  //     key: '2',
  //     label1: 'Out',
  //     data1: formatNumberWithCommas(withdraw_amount),
  //     label2: 'Total Out',
  //     data2: formatNumberWithCommas(total_withdraw_amount),
  //   },
  // ];

  const dataIn = [
    {
      key: '1',
      label1: 'In',
      data1: formatNumberWithCommas(deposit_amount),
      label2: 'Total In',
      data2: formatNumberWithCommas(total_deposit_amount),
    },
  ];

  const dataOut = [
    {
      key: '1',
      label1: 'Out',
      data1: formatNumberWithCommas(withdraw_amount),
      label2: 'Total Out',
      data2: formatNumberWithCommas(total_withdraw_amount),
    },
  ];

  const onFilterChange = (name: string, values: CheckboxValueType[]) => {
    setFiltersData((prevState: any) => ({ ...prevState, [name]: values }));
  };

  const onAllCheck = (name: string, values: CheckboxValueType[]) => {
    setFiltersData(prevState => ({ ...prevState, [name]: values }));
  };

  const handleDateChange = (date: Dayjs | null, key: string) => {
    if (date) {
      setLocalData(prevState => ({
        ...prevState,
        [key]: date,
      }));
    }
  };

  const onFinish = () => {
    const hasFilters = Object.keys(filtersData).length > 0;
    if (id && hasFilters) {
      const obj = {
        opType: [
          ...(filtersData.IN || []),
          ...(filtersData.OUT || []),
          ...(filtersData.BONUS || []),
        ],
        aa_status: [...(filtersData.AppType || [])],
        status: [...(filtersData.Status || [])],
        appType: filtersData.APP,
        dateFrom: localData.dateFrom,
        dateTo: localData.dateTo,
        playerId: parseInt(id),
        limit: pageSize,
        page: currentPage,
        orderBy: 'updated_at',
        orderDir: 'DESC',
      };
      dispatch(getPlayerTrxThunk(obj));
      setCurrentPage(1);
    }
  };

  return (
    <>
      {trxFilters.loading ? (
        <GlobalLoader />
      ) : (
        <Form layout='vertical' labelCol={{ span: 10 }}>
          <Row gutter={16}>
            <Col span={4}>
              <Form.Item label='Start Date'>
                <DatePicker
                  showTime
                  value={localData.dateFrom}
                  onChange={date => handleDateChange(date, 'dateFrom')}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label='End Date'>
                <DatePicker
                  showTime
                  value={localData.dateTo}
                  onChange={date => handleDateChange(date, 'dateTo')}
                />
              </Form.Item>
            </Col>
          </Row>
          {localFilters.map(([key, options]: [string, { name: string }[]]) => {
            return (
              <Row
                style={{
                  paddingBottom: 5,
                  paddingLeft: 5,
                  flexWrap: 'nowrap',
                }}
                key={key}>
                <CheckboxGroup
                  span={1}
                  name={key}
                  options={(options as any).map(
                    (item: { name: string }) => item?.name || '',
                  )}
                  value={filtersData[key] || []}
                  onFilterChange={onFilterChange}
                  onAllCheck={onAllCheck}
                />
              </Row>
            );
          })}
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type='primary' onClick={onFinish}>
              Search
            </Button>
          </Col>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              margin: 10,
            }}>
            <Table
              columns={columnsInOut}
              dataSource={dataIn}
              pagination={false}
              bordered
              style={{ width: '45%' }}
              showHeader={false}
            />
            <Table
              columns={columnsInOut}
              dataSource={dataOut}
              pagination={false}
              bordered
              style={{ width: '45%' }}
              showHeader={false}
            />
          </div>
        </Form>
      )}
    </>
  );
};

export default TRXTabFilter;
