/* eslint-disable */

import { FC, useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Row, Select } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import dayjs, { Dayjs } from 'dayjs';

import { useAppDispatch } from 'redux/hooks/redux.hooks';
import { getPlayerSportThunk } from 'redux/reducers/players/sport/playerSportThunk';

import { getSportCasinoGamesData } from 'redux/reducers/players/balanceLog/balanceLogThunk';
import { useSelector } from 'react-redux';
import { setChoosenSportBetData } from 'redux/reducers/players/playersSlice';
import CheckboxGroup from 'components/Routes/Transactions/TransactionFilters/CheckboxGroup';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { filtersConfig } from '../../helpers/costants';

const { RangePicker } = DatePicker;

interface SportProps {
  currentPage: any;
  pageSize: any;
  choosenId: string;
  id: any;
}

const SportTabFilter: FC<SportProps> = ({
  currentPage,
  pageSize,
  choosenId,
  id,
}) => {
  const [localData, setLocalData] = useState<any>({
    date_from: dayjs().subtract(1, 'week').startOf('day'),
    date_to: dayjs().endOf('day').set('hour', 23).set('minute', 59),
  });
  const [localFilters, setLocalFilters] = useState<{
    [key: string]: CheckboxValueType[];
  }>({});
  const dispatch = useAppDispatch();
  const activeTabKey = useSelector((state: any) => state.players.activeTabKey);
  const { loading } = useSelector((state: any) => state.playerSportSlice);

  const handleRangeChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (dates) {
      const [start, end] = dates;
      setLocalData((prevState: any) => ({
        ...prevState,
        date_from: start
          ? dayjs(start.format())
          : dayjs().subtract(1, 'week').startOf('day'),
        date_to: end
          ? dayjs(end.format())
          : dayjs().endOf('day').set('hour', 23).set('minute', 59),
      }));
    }
  };

  useEffect(() => {
    if (id && activeTabKey === '4' && !choosenId) {
      const requestData = {
        limit: pageSize,
        page: currentPage,
        filters: {
          ...localData,
          ztype: localFilters['Bet Type'] || [],
          status: localFilters['Status'] || [],
          money_options: localFilters['Betting Option'] || [],
          player_id: parseInt(id),
        },
      };
      dispatch(getPlayerSportThunk(requestData));
      dispatch(
        getSportCasinoGamesData({
          player_id: parseInt(id),
          date_from: localData.date_from,
          date_to: localData.date_to,
          sections: ['sport'],
        }),
      );
    }
  }, [currentPage, pageSize, activeTabKey]);

  useEffect(() => {
    dispatch(setChoosenSportBetData({ id: null, date: null }));
  }, [activeTabKey]);

  const onSearch = () => {
    if (id) {
      const requestData = {
        limit: pageSize,
        page: currentPage,
        filters: {
          ...localData,
          ztype: localFilters['Bet Type'] || [],
          status: localFilters['Status'] || [],
          money_options: localFilters['Betting Option'] || [],
          player_id: parseInt(id),
        },
      };
      dispatch(getPlayerSportThunk(requestData));
      dispatch(
        getSportCasinoGamesData({
          player_id: parseInt(id),
          date_from: localData.date_from,
          date_to: localData.date_to,
          sections: ['sport'],
        }),
      );
    }
  };

  const onFilterChange = (name: string, values: any[]) => {
    setLocalFilters((prevState: any) => ({ ...prevState, [name]: values }));
  };

  const onAllCheck = (name: string, values: any[]) => {
    setLocalFilters((prevState: any) => ({ ...prevState, [name]: values }));
  };

  return (
    <Form layout='vertical' labelCol={{ span: 10 }}>
      <Row>
        <Col span={12}>
          <FormItem label='Select Range'>
            <RangePicker
              showTime
              defaultValue={[localData.date_from, localData.date_to]}
              onChange={handleRangeChange}
            />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={20}>
          {filtersConfig.map(([key, options]) => (
            <Row
              key={key}
              style={{
                paddingBottom: 5,
                paddingLeft: 5,
                flexWrap: 'nowrap',
              }}>
              <CheckboxGroup
                span={4}
                name={key}
                options={options.map((item: { label: string }) => item.label)}
                value={localFilters[key] || []}
                onFilterChange={onFilterChange}
                onAllCheck={onAllCheck}
              />
            </Row>
          ))}
        </Col>
        <Col span={3}>
          <Row>&nbsp;</Row>
          <Form.Item label='&nbsp;'>
            <Button disabled={loading} onClick={onSearch} type='primary'>
              Search
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SportTabFilter;
